import * as React from "react";
import { PageProps, graphql } from "gatsby";

import Layout from "../components/layout";
import { useTranslation } from "react-i18next";
import { ContactFormField } from "../components/contactFormField";
import { ContactFormCheckboxField } from "../components/contactFormCheckboxField";
import { useContactForm } from "../hooks/useContactForm";
import { CreateAccountForm } from "../../netlify/functions/submit-create-account-form";
import { TranslatedLink } from "gatsby-plugin-translate-urls";

type DataProps = {
  site: {
    buildTime: string;
  };
};

const CreateInspectoAccount: React.FC<PageProps<DataProps>> = ({
  location,
}) => {
  const { t } = useTranslation();
  const [isSending, response, submitContactForm, reCaptchaElement] =
    useContactForm();

  return (
    <Layout
      noIndex
      seoTitle={t("createInspectoAccount.title")}
      seoDescription={t("createInspectoAccount.description")}
      location={location}
    >
      <main className="page-auth">
        <div className="container">
          <section className="auth-wrapper">
            <div className="row">
              <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                <h1 className="auth-section-title">
                  {t("createInspectoAccount.title")}
                </h1>
                <p className="auth-section-subtitle">
                  {t("createInspectoAccount.description")}
                </p>
                <form
                  method="POST"
                  action="/.netlify/functions/submit-create-account-form"
                  onSubmit={submitContactForm}
                >
                  <ContactFormField<CreateAccountForm> fieldName="fullName" />
                  <div className="row">
                    <ContactFormField<CreateAccountForm>
                      fieldName="companyName"
                      className="col-lg-6"
                    />
                    <ContactFormField<CreateAccountForm>
                      fieldName="taxIdentificationNumber"
                      className="col-lg-6"
                    />
                  </div>

                  <div className="row">
                    <ContactFormField<CreateAccountForm>
                      fieldName="street"
                      className="col-md-6"
                    />
                    <ContactFormField<CreateAccountForm>
                      fieldName="homeNo"
                      className="col-md-3"
                    />
                    <ContactFormField<CreateAccountForm>
                      required={false}
                      fieldName="localNo"
                      className="col-md-3"
                    />
                  </div>
                  <div className="row">
                    <ContactFormField<CreateAccountForm>
                      fieldName="postCode"
                      className="col-md-6"
                    />
                    <ContactFormField<CreateAccountForm>
                      fieldName="city"
                      className="col-md-6"
                    />
                  </div>

                  <ContactFormField<CreateAccountForm>
                    fieldName="accountEmail"
                    type="email"
                  />
                  <ContactFormField<CreateAccountForm>
                    fieldName="billingEmail"
                    type="email"
                  />
                  <ContactFormCheckboxField<CreateAccountForm>
                    fieldName="applicationRegulationsAccepted"
                    labelSuffix={
                      <>
                        {" "}
                        <TranslatedLink
                          to={"/application-rules"}
                          target="_blank"
                        >
                          {t(
                            "contact.contactForm.applicationRegulationsAcceptedPart2"
                          )}
                        </TranslatedLink>
                      </>
                    }
                  />
                  <ContactFormCheckboxField<CreateAccountForm> fieldName="gdprAgreement" />
                  <div className="mb-3">{reCaptchaElement}</div>
                  {response === "ok" ? (
                    <div className="alert alert-success mb-5" role="alert">
                      <h4 className="alert-heading">
                        {t(
                          "createInspectoAccount.responseMessage.successHeader"
                        )}
                      </h4>
                      <p>
                        {t("createInspectoAccount.responseMessage.success")}
                      </p>
                      <hr />
                      <p className="mb-0">
                        {t(
                          "createInspectoAccount.responseMessage.successFooter"
                        )}
                      </p>
                    </div>
                  ) : response === "notOk" ? (
                    <div className="alert alert-danger mb-5" role="alert">
                      {t("createInspectoAccount.responseMessage.failure")}
                    </div>
                  ) : null}
                  <button
                    className="btn btn-primary btn-auth-submit"
                    type="submit"
                    disabled={response === "ok" || isSending}
                  >
                    {t("createInspectoAccount.title")}
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
      </main>
    </Layout>
  );
};

export default CreateInspectoAccount;

export const query = graphql`
  query ($language: String!) {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
